import React from "react";

import Layout from "../../components/legal/layout";
import Head from "../../components/head";

import styles from "../../styles/legal/legal.module.scss";

const PrivacyPage = () => (
	<Layout location="privacy">
		<div className={styles.content}>
			<Head title="Privacy policy" />
			
			<h1 className={styles.heading}>Privacy policy</h1>
			<div>
				<h2>Privacy (transparency) notice pursuant to General Data Protection Regulation Arts 13 and 14.</h2>

				<p>This notice is intended for individuals with whom we deal in our business, including candidates for jobs with us. It is not intended for our employees. We categorise those individuals (“data subjects”) as follows:</p>

				<ul>
				    <li>Individuals - academics, the retired, those who deal with us in their capacity as employees or owners of businesses which have other employees, those between jobs, sole traders without employees and those who only contact us because of their personal interest in our business and technology.</li>

					<li>Candidates - those who have applied for a job with us.</li>
				</ul>

				<p>We obtain and process personal data in different ways depending upon the data subject, the purpose of the processing and way in which we obtained your personal data.</p>

				<p>In most circumstances, your data will only be processed within the UK or EEA. If for any reason we need to have your data processed elsewhere we will inform you of that, any privacy risks involved and what we have done to minimise those risks. In most circumstances, we would also seek your consent for that.</p>

				<p>You can at any time ask us to tell you what personal data relating to you we hold.</p>

				<p>You also have the right to ask us to rectify your data if it is inaccurate, erase it or restrict the processing of it. In some circumstances, you can ask us to provide it to you in a format which would allow you to transfer it digitally to another.</p>

				<p>Where we have requested and you have given us consent to process your data you may withdraw that consent at any time.</p>

				<p>Were our business to be sold all your data would be transferred to the purchaser so that they could use it in the same way as we do now.</p>

				<p>If you are at all concerned about our processing of your data please let us know by emailing <a href="mailto:privacy@criticaltechworks.com">privacy@criticaltechworks.com.</a></p>

				<p>If you deal with us as an Individual, the following applies:</p>

				<h2>Individual Data</h2>

				<p><strong>Purpose.</strong> We process your data in order to respond to you and so that we can contact you about matters of mutual interest, and send you white papers, case studies and other related materials that may be of interest.</p>

				<p><strong>Legitimate Interest.</strong> We have a legitimate interest in doing that as you will have indicated that you wish us to do so. We do not need your consent.</p>

				<p><strong>Marketing.</strong> If we were to use your data in order to send you marketing material about our services we will only do so with your consent. That consent can be withdrawn by you at any time.</p>

				<p>Those with whom we share it. Some of our data, which could include your personal data, is stored off site by third parties. None of our personal data is stored outside the EEA or UK.</p>

				<p><strong>Retention.</strong> Your data will be retained for up to ten years after our last interaction with you unless you ask us to delete it earlier and we are able to do that.</p>

				<p><strong>Why we need your data.</strong> Without your data we would not have the information necessary to be able to communicate with you.</p>

				<p><strong>Where we get it.</strong> Most of your data held by us will have come from you. Some may have been obtained from your colleagues and friends.</p>

				<p>If you are a Candidate for a job with us, the following applies:</p>

				<h2>Candidates</h2>

				<p><strong>Purpose.</strong> We process your data to enable us to assess you for a role and contact you.</p>

				<p><strong>Legitimate Interest.</strong> We have a legitimate interest in using your data for those purposes. We do not need your consent.</p>

				<p><strong>Those with whom we share it.</strong> Some of our data, which could include your personal data, is stored off site by third parties. None of our personal data is stored outside the EEA or UK. We may, if your role would be working for one of our customers, provide it to that customer to allow them to assess you. If we seek references for you, we will supply your name and details of the proposed role to your referees. If we wish to verify your qualifications or suitability we may provide information sufficient to identify you, things that you have told us about yourself and the proposed role to those that can verify the information you have given us. Additionally, we may share your information with our subcontracted recruitment services for screening, interviewing and follow-up activities.</p>

				<p><strong>Retention.</strong> If we decide not to employ you, most personal data about you will be deleted/destroyed six months after that decision is made and we will retain only sufficient data to identify that we have considered you for employment but that your application was unsuccessful. If we think we might want to employ you in future and tell you so, we will retain indefinitely all the data that we have about you. If we employ you, all the data about you will be transferred to your HR file and used and retained in accordance with our policies for employment.</p>

				<p><strong>Why we need your data.</strong> Without your data we would be unable to assess you, or contact you.</p>

				<p><strong>Where we got it.</strong> The usual source of your data is you or a recruitment consultant or “job board”. Some data may come from referees or those we use to verify the information that you provide.</p>

				<h2>Other Disclosures</h2>

				<p>In addition to the disclosures reasonably necessary for the purposes identified elsewhere in this privacy policy, we may disclose information about you:</p>

				<ul>
					<li>To the extent that we are required to do so by law</li>
					<li>In connection with any legal proceedings or prospective legal proceedings</li>
					<li>In order to establish, exercise or defend our legal rights </li>
					<li>To the purchaser (or prospective purchaser) of any business or asset which we are (or are contemplating) selling</li>
				</ul>

				<p>Except as provided in our privacy policy, we will NEVER provide your information to third parties.</p>

				<h2>Security of Your Personal Data</h2>

				<p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>

				<p>Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>

				<h3>Policy Amendments</h3>
				<p>We may update this privacy policy from time-to-time by posting a new version on our website.</p>

				<h3>Third-Party Websites</h3>
				<p>The website contains links to other websites. We are not responsible for the privacy policies or practices of third-party websites.</p>

				<h3>Updating Information</h3>
				<p>Please let us know if the personal information which we hold about you needs to be corrected or updated.</p>

				<h3>Contact</h3>
				<p>If you have any questions about this privacy policy or our treatment of your personal data, please contact us or write to us at Critical TechWorks, Rua do Campo Alegre, nº17, 1º Andar 4050-481 Porto, Portugal.</p>
			</div>
		</div>
	</Layout>
);

export default PrivacyPage;
