import React from "react";
import { Link } from "gatsby";

import styles from "../../styles/legal/footer.module.scss";

const Footer = () => (
	<footer className={styles.footer}>
		<Link className={styles.footerLink} to="/">Back to main page</Link>
	</footer>
);

export default Footer;
