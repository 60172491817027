import React from "react";
import { Link } from "gatsby";

import styles from "../../styles/legal/header.module.scss";

const Header = ({ location }) => {
	const menuItem = (itemLocation, linkText) => {
		if (location === itemLocation) {
			return <span className={styles.menulink_current}>{linkText}</span>;
		}
		return <Link className={styles.menulink} to={`/legal/${itemLocation}`}>{linkText}</Link>;
	};
	return (
		<header className={styles.header}>
			{menuItem('terms', 'Terms of use')}
			{menuItem('privacy', 'Privacy Policy')}
			{menuItem('cookies', 'Cookies')}
		</header>
	);
};

export default Header;
